





















import Vue from 'vue';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  data: () => ({
    subscriptions: {},
  }),
  computed: {
    columns(): any {
      return [
        { value: this.$t('page.gastroJournal.table.subscriptionName'), field: 'name' },
        { value: this.$t('page.gastroJournal.table.subscriptionStartDate'), field: 'subscriptionStartDate', format: (date: string) => this.$format.localDate(date) },
        { value: this.$t('page.gastroJournal.table.subscriptionEndDate'), field: 'subscriptionEndDate', format: (date: string) => this.$format.localDate(date) },
      ];
    },
  },
  methods: {
    gotoDetail(id: string) {
      this.$router.push('/gs/memberPortal/gastro-journal/subscription/detail');
    },
    async populateSubscriptionList() { // params: {query: PagingQuery}) {
      const response = await this.$service.api.subscriptions.getMyGastroJournalSubscriptions();
      return response;
    },
  },
});
